<template>
  <div ref="carousel" class="dp-top-pro-recipe-carousel">
    <div class="swiper-wrapper">
      <slot />
    </div>

    <div ref="pagination" :class="paginationClasses" />
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  computed,
  onMounted
} from '@vue/composition-api';

// @see https://swiperjs.com/
import Swiper, { Pagination, Autoplay, EffectFade } from 'swiper';
import lodash from 'lodash';

export default defineComponent({
  props: {
    options: {
      type: Object,
      default: null
    },

    contents: {
      type: Array,
      default: undefined
    }
  },

  setup: (props) => {
    const carousel = ref(undefined);
    const pagination = ref(undefined);
    const swiper = ref(undefined);

    const paginationClasses = computed(() => [
      'dp-top-pro-recipe-carousel-pagination',
      props.contents.length > 1
        ? false
        : 'dp-top-pro-recipe-carousel-pagination--hidden'
    ]);

    const init = () => {
      const initOptions = {
        modules: [Pagination, Autoplay, EffectFade],
        slidesPerView: 1,
        spaceBetween: 0,
        allowTouchMove: true,
        /* eslint-disable indent */
        autoplay:
          props.contents.length > 1
            ? {
                disableOnInteraction: false
              }
            : false,
        /* eslint-enable */

        speed: 500,
        loop: true,

        effect: 'fade',
        fadeEffect: {
          crossFade: true
        },

        pagination: {
          clickable: false,
          el: pagination.value,
          type: 'bullets',
          bulletClass: 'dp-top-pro-recipe-carousel-pagination__bullet',
          bulletActiveClass:
            'dp-top-pro-recipe-carousel-pagination__bullet--active'
        }
      };
      const mergedOptions = lodash.merge(initOptions, props.options);
      swiper.value = new Swiper(carousel.value, mergedOptions);
    };

    onMounted(async () => {
      init();
    });

    return {
      carousel,
      pagination,
      paginationClasses
    };
  }
});
</script>

<style lang="scss" scoped>
@use 'swiper/modules/autoplay/autoplay.scss';
@use 'swiper/modules/effect-fade/effect-fade.scss';
@use '@/assets/scss/variables';
@use '@/assets/scss/mixin';

.dp-top-pro-recipe-carousel {
  position: relative;
  margin: 0 -16px 8px;

  .swiper-slide {
    height: auto;
  }
}

.dp-top-pro-recipe-carousel-pagination::v-deep {
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 69px;
  padding: 16px;

  .dp-top-pro-recipe-carousel-pagination__bullet {
    padding: 0 4px;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    &::before {
      display: block;
      content: '';
      background-color: variables.$dpGrey88;
      width: 24px;
      height: 3px;
    }

    &--active {
      &::before {
        background-color: variables.$dpWhite01;
      }
    }
  }
}

.dp-top-pro-recipe-carousel-pagination--hidden::v-deep {
  padding: 0 0 8px;
  height: 0;
  opacity: 0;
}
</style>
